import type { UseQueryOptions } from '@tanstack/vue-query'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import type { MaybeRef } from 'vue'
import { getError } from '@autobid/strapi-integration/utils/getError'
import { useCustomFetch } from '../useHttp'

type NoteApiResponse = {
  data: {
    code: 200
    note: string
  }
}

export function useNote(
  carId: MaybeRef<number>,
  options?: UseQueryOptions<NoteApiResponse, Error>
) {
  const { locale } = useI18n()
  const queryClient = useQueryClient()
  const push = usePush()
  const { $customFetch } = useCustomFetch()

  const queryFn = async () => {
    return await $customFetch<NoteApiResponse>('/api/backend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        queryMethod: 'GET',
        queryApi: 'webapi',
        headers: {
          'Accept-Language': locale.value
        },
        queryUrl: `/v1/cars/${unref(carId)}/note`
      }
    })
  }

  const { data: note, ...rest } = useQuery({
    queryKey: ['note', unref(carId)],
    queryFn,
    onError: (error) => {
      push.error(getError(error).message)
    },
    ...(options ?? {})
  })

  const prefetch = () =>
    queryClient.prefetchQuery({
      queryKey: ['note', unref(carId)],
      queryFn
    })

  return { note, prefetch, ...rest }
}
