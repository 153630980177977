<template>
  <template v-if="isAuthed">
    <ElementsCarWatch
      v-if="!car.hasBid && car.stage !== 'FINISHED'"
      :car-id="car.id"
      :watched="car.isWatcher"
      :size="size"
      v-bind="$attrs"
      @update="handleUpdateWatch"
    />
    <ElementsCarNote :car-id="car.id" :size="size" v-bind="$attrs" />
    <DynamicAuctionItemListCarActionsTransport
      v-if="car.stage !== 'FINISHED'"
      v-bind="$attrs"
      :size="size"
      :car="car"
    />
  </template>
</template>

<script lang="ts" setup>
import { useUpdateWatch } from '@autobid/ui/composables/car/useUpdateWatch'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import type { AuctionCar } from '@autobid/ui/types/Car'

interface Props {
  car: AuctionCar
  size: 'dynamic' | 'big'
}

const props = defineProps<Props>()

const { isAuthed } = useAutobidAuth()
const { update } = useUpdateWatch()
const handleUpdateWatch = (value: boolean) => {
  update(props.car.id, value)
}
</script>
