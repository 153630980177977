<template>
  <template v-if="isAuthed">
    <DynamicAuctionItemListCarActionsBidded
      v-if="car.hasBid"
      :size="size"
      v-bind="$attrs"
    />
    <DynamicAuctionItemListCarActionsSca
      v-if="car.status.bmwBankFinancingAvailable"
      :slug="scaPopupSlug"
      :size="size"
      v-bind="$attrs"
    />
    <DynamicAuctionItemListCarActionsBank11
      v-if="car.status.bank11FinancingAvailable"
      v-bind="$attrs"
      :size="size"
    />
  </template>
</template>

<script lang="ts" setup>
import type { AuctionCar } from '@autobid/ui/types/Car'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'

interface Props {
  scaPopupSlug: string
  car: AuctionCar
  size: 'small' | 'big'
}

defineProps<Props>()
const { isAuthed } = useAutobidAuth()
</script>
